import {
  ReactNode,
  createContext,
  useContext,
  useState,
  useEffect,
} from "react";

import { IBreadcrumbsItem } from "../components/breadcrumbs";
import { ICurrentPageProvider } from "../models";
import { useTranslation } from "./useTranslation";

export const CurrentPageContext = createContext({});

export const CurrentPageProvider = ({ children }: { children: ReactNode }) => {
  const [breadcrumbsItems, setBreadcrumbsItems] = useState<IBreadcrumbsItem[]>(
    [],
  );
  const { t } = useTranslation();

  const setPageTitle = (title: string) => {
    document.title = t("pageTitle", { pageTitle: title });
  };

  useEffect(() => {
    setBreadcrumbsItems([]);
  }, [children]);

  return (
    <CurrentPageContext.Provider
      value={{
        setPageTitle,
        breadcrumbsItems,
        setBreadcrumbsItems,
      }}
    >
      {children}
    </CurrentPageContext.Provider>
  );
};

export const useCurrentPage = () =>
  useContext(CurrentPageContext) as ICurrentPageProvider;
