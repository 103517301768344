import { FieldBase } from "@nvs-dynamic-form/react-core";
import { TextAreaProps } from "@nextui-org/react";

export class TextareaField extends FieldBase<string> {
  override readonly fieldType? = "textarea";
  placeholder?: string;
  minRowSize?: number;
  maxRowSize?: number;

  constructor(options: Omit<TextareaField, "fieldType">) {
    super(options, "");
    this.placeholder = options.placeholder ?? "";
    this.minRowSize = options.minRowSize;
    this.maxRowSize = options.maxRowSize;
  }
}

export type ITextarea = TextareaField & TextAreaProps;
