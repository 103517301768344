import {
  IAuthProvider,
  IUserPermission,
  PermissionType,
  Roles,
  User,
} from "../models";
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { AuthService } from "../utils/services";

export const AuthContext = createContext({});

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [tokenDecode, setTokenDecode] = useState(AuthService.getTokenDecode());
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [isAuth, setIsAuth] = useState<boolean>(!!tokenDecode);
  const [permissions, setPermissions] = useState<{
    [PermissionType.USER_TRANSACTIONS]: boolean;
    [PermissionType.COMPANY_TRANSACTIONS]: boolean;
    [PermissionType.CORRIDOR_TRANSACTIONS]: boolean;
    [PermissionType.FILE_TRANSACTIONS]: boolean;
  }>({
    [PermissionType.USER_TRANSACTIONS]: false,
    [PermissionType.COMPANY_TRANSACTIONS]: false,
    [PermissionType.CORRIDOR_TRANSACTIONS]: false,
    [PermissionType.FILE_TRANSACTIONS]: false,
  });

  const logout = () => {
    AuthService.deleteToken();
    setIsAuth(false);
  };

  const setToken = (token: string) => {
    AuthService.setToken(token);
    setIsAuth(true);
    setTokenDecode(AuthService.getTokenDecode());
  };

  const checkPermissions = useCallback(
    (userRole: Roles, userPermissions: Array<IUserPermission>) => {
      let newPermissions: { [key in PermissionType]?: boolean } = {};
      if (userRole === Roles.USER)
        for (const userPermission of userPermissions) {
          newPermissions[
            userPermission.permission!.key.toUpperCase() as PermissionType
          ] = true;
        }
      else {
        newPermissions = {
          [PermissionType.USER_TRANSACTIONS]: true,
          [PermissionType.COMPANY_TRANSACTIONS]: true,
          [PermissionType.CORRIDOR_TRANSACTIONS]: true,
          [PermissionType.FILE_TRANSACTIONS]: true,
        };
      }
      setPermissions((prevPermissions) => {
        return { ...prevPermissions, ...newPermissions };
      });
    },
    [],
  );

  const setAuthUser = useCallback(
    (user: User | null) => {
      if (
        user?.role === tokenDecode?.role &&
        user?.companyId === tokenDecode?.companyId &&
        user?.isActive === true
      ) {
        const { permissions, ...userInfo } = user;
        setCurrentUser(userInfo);
        checkPermissions(user.role, permissions!);
      } else logout();
    },
    [checkPermissions, tokenDecode],
  );

  useEffect(() => {
    if (tokenDecode) {
      AuthService.getCurrentUserAsync()
        .then((user) => {
          setAuthUser(user);
        })
        .catch(() => {
          logout();
        });
    }
  }, [tokenDecode, setAuthUser]);

  return (
    <AuthContext.Provider
      value={useMemo(
        () => ({
          currentUser,
          setToken: setToken,
          isAuth,
          logout: logout,
          permissions,
        }),
        [currentUser, isAuth, permissions],
      )}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext) as IAuthProvider;
