import { FieldBase } from "@nvs-dynamic-form/react-core";
import { SwitchProps } from "@nextui-org/switch";
import { ColorType } from "../../models";

export class SwitchField extends FieldBase<boolean> {
  override readonly fieldType? = "switch";
  size?: "sm" | "md" | "lg";
  color?: ColorType;

  constructor(options: Omit<SwitchField, "fieldType">) {
    super(options, false);
    this.size = options.size ?? "md";
    this.color = options.color ?? "primary";
  }
}

export type ISwitch = SwitchField & SwitchProps;
