import { FieldBase } from "@nvs-dynamic-form/react-core";
import { CheckboxProps } from "@nextui-org/checkbox";
import { ColorType } from "../../models";

export class CheckboxField extends FieldBase<boolean> {
  override readonly fieldType? = "checkbox";
  size?: "sm" | "md" | "lg";
  color?: ColorType;

  constructor(options: Omit<CheckboxField, "fieldType">) {
    super(options, false);
    this.size = options.size ?? "md";
    this.color = options.color ?? "primary";
  }
}

export type ICheckbox = CheckboxField & CheckboxProps;
