import { Button as NextUIButton } from "@nextui-org/button";
import { IButton } from "./_type";

export default function Button({
  children,
  iconName,
  iconPosition = "left",
  ...props
}: Readonly<IButton>) {
  const iconControl = children && icon(iconName);

  return (
    <NextUIButton
      type="submit"
      variant="solid"
      color="primary"
      endContent={iconPosition === "right" && iconControl}
      startContent={iconPosition === "left" && iconControl}
      {...props}
    >
      {children ?? icon(iconName)}
    </NextUIButton>
  );
}

const icon = (iconName: string | undefined) => {
  return iconName ? <i className={`ri-${iconName} text-xl`}></i> : "";
};
