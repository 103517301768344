import { Roles, User } from "../../models";

import { apolloClient } from "../clients/apolloClient";
import { gql } from "@apollo/client";
import { jwtDecode } from "jwt-decode";

export class AuthService {
  private static readonly tokenLocalStorageKey = "@token";
  private static readonly userLocalStorageKey = "@user";

  public static async getCurrentUserAsync(): Promise<User | null> {
    const tokenDecode = AuthService.getTokenDecode();

    if (!tokenDecode) return null;

    const user = await apolloClient.query<{ usersByPk: User }>({
      query: gql`
        query getUser($userId: Int!) {
          usersByPk(id: $userId) {
            id
            fullName
            firstName
            isActive
            lastName
            emailAddress
            companyId
            role
            permissions {
              id
              permission {
                key
                id
              }
            }
          }
        }
      `,
      variables: { userId: tokenDecode.userId },
    });

    return user.data.usersByPk;
  }
  
  public static getToken(): string | null {
    return window.localStorage.getItem(this.tokenLocalStorageKey);
  }

  public static setToken(token: string): void {
    window.localStorage.setItem(this.tokenLocalStorageKey, token);
  }

  public static deleteToken(): void {
    window.localStorage.removeItem(this.tokenLocalStorageKey);
  }

  public static getTokenDecode() {
    const token = AuthService.getToken();
    if (!token) return null;

    return jwtDecode<{
      companyId: number;
      userId: number;
      role: Roles;
    }>(token);
  }
}
