import {
  NavbarContent,
  NavbarItem,
  NavbarMenuToggle,
  Navbar as NextUINavbar,
} from "@nextui-org/react";

import { INavbar } from "./_type";
import ProfileMenu from "../profileMenu";

export default function Navbar({
  user,
  menuItems,
  onChangeMenu = () => {},
}: Readonly<INavbar>) {
  return (
    <NextUINavbar
      onMenuOpenChange={onChangeMenu}
      isBordered
      maxWidth="full"
      className="h-[4.5rem] border-none"
    >
      <NavbarContent className="sm:hidden">
        <NavbarMenuToggle className="sm:hidden" />
      </NavbarContent>
      <NavbarContent justify="end">
        <NavbarItem>
          {user && <ProfileMenu user={user} menuItems={menuItems} />}
        </NavbarItem>
      </NavbarContent>
    </NextUINavbar>
  );
}
