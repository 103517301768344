import { ILoading } from "./_type";
import { Spinner } from "@nextui-org/react";

export default function Loading({
  size = "sm",
  color = "default",
  labelColor = "default",
  label,
  className,
}: Readonly<ILoading>) {
  return (
    <Spinner
      className={className}
      size={size}
      color={color}
      label={label}
      labelColor={labelColor === "default" ? "foreground" : labelColor}
    />
  );
}
