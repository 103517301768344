import { Select, SelectItem } from "@nextui-org/react";

import { IDropdown } from "./_type";

export * from "./_type";

export default function Dropdown({
  options,
  variant = "bordered",
  onSelectionChange,
  ...props
}: Readonly<IDropdown>) {
  return (
    <Select
      isInvalid={!!props.error}
      errorMessage={props.error}
      onSelectionChange={(value) =>
        onSelectionChange && onSelectionChange(value)
      }
      variant={variant}
      disabledKeys={options
        .filter((option) => option.disabled)
        .map((option) => option.value.toString())}
      defaultSelectedKeys={props?.value ? [String(props.value)] : []}
      {...props}
    >
      {options.map((item) => (
        <SelectItem {...item} key={item.value} value={item.value}>
          {item.label}
        </SelectItem>
      ))}
    </Select>
  );
}
