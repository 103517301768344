import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { configurations } from "../../config/configurations";
import { useTranslation } from "../../hooks";

export default function AuthLayout({ children }: { children: JSX.Element }) {
  const { t } = useTranslation();
  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={configurations.recaptchaSiteKey as string}
      scriptProps={{
        appendTo: "body",
      }}
    >
      <section>
        <div className="grid gap-0 md:grid-cols-3 overflow-hidden h-[100vh]">
          <div
            className={
              "transition-all hidden md:flex items-start justify-center bg-[url('/public/images/auth-background.jpg')] bg-center bg-cover"
            }
          >
            <div className="mx-auto max-w-md px-5 py-16 md:px-10 md:py-24 lg:py-32 ">
              <div className="mb-5 flex flex-col ">
                <i className="ri-double-quotes-l text-cyan-700 text-[50px] -mb-8" />
              </div>
              <p className="mb-8 md:mb-12 lg:mb-16 text-white text-3xl">
                {t("authLayout.quote")}
              </p>
              <p className="text-sm font-bold sm:text-base text-slate-400">
                Pamela McCorduck
              </p>
              <p className="text-sm sm:text-sm text-white">
                {t("authLayout.quoteAuthorTitle")}
              </p>
            </div>
          </div>
          {children}
        </div>
      </section>
    </GoogleReCaptchaProvider>
  );
}
