import { ITranslationProvider, Language } from "../models";
import { ReactNode, createContext, useContext, useMemo } from "react";

import { useTranslation as useI18nTranslation } from "react-i18next";

export const TranslationContext = createContext({});

export const TranslationProvider = ({ children }: { children: ReactNode }) => {
  const { t, i18n } = useI18nTranslation();
  const currentLocale = window.localStorage.getItem("@language");
  if (currentLocale) i18n.changeLanguage(currentLocale);

  return (
    <TranslationContext.Provider
      value={useMemo(
        () => ({
          locale: i18n.language,
          t,
          translate: t,
          setLanguage: (locale: Language) => {
            i18n.changeLanguage(locale);
            window.localStorage.setItem("@language", locale.toString());
          },
        }),
        [i18n, t]
      )}
    >
      {children}
    </TranslationContext.Provider>
  );
};

export const useTranslation = () =>
  useContext(TranslationContext) as ITranslationProvider;
