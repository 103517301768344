import { IInput } from "./_type";
import { Input as NextUIInput } from "@nextui-org/input";

export * from "./_type";

export default function Input({
  variant = "bordered",
  iconName,
  onIconClick,
  ...props
}: IInput) {
  return (
    <NextUIInput
      variant={variant}
      fullWidth={true}
      isInvalid={props.error ? true : false}
      errorMessage={props.error}
      endContent={
        iconName && onIconClick ? (
          <InputIconButton iconName={iconName} onIconClick={onIconClick} />
        ) : (
          <InputIcon iconName={iconName!} />
        )
      }
      {...props}
    />
  );
}

const InputIcon = ({ iconName }: { iconName: string }) => {
  return <i className={`${iconName} text-2xl`}></i>;
};

const InputIconButton = ({
  iconName,
  onIconClick,
}: {
  iconName: string;
  onIconClick: () => void;
}) => {
  return (
    <button
      className="focus:outline-none"
      type="button"
      onClick={() => onIconClick!()}
    >
      <InputIcon iconName={iconName!} />
    </button>
  );
};
