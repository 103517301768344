import { FieldBase } from "@nvs-dynamic-form/react-core";
import { CheckboxGroupProps } from "@nextui-org/checkbox";
import { ColorType } from "../../models";

export interface ICheckboxItem {
  label: string;
  value: string | number;
}

export class CheckboxGroupField extends FieldBase<string[]> {
  override readonly fieldType? = "checkboxGroup";
  options: ICheckboxItem[];
  color?: ColorType;
  orientation?: "horizontal" | "vertical";

  constructor(options: Omit<CheckboxGroupField, "fieldType">) {
    super(options, []);
    this.options = options.options ?? [];
    this.color = options.color ?? "primary";
    this.orientation = options.orientation ?? "horizontal";
  }
}

export type ICheckboxGroup = CheckboxGroupField & CheckboxGroupProps;
