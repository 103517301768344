import { Switch as NextUISwitch } from "@nextui-org/switch";
import { ISwitch } from "./_type";

export * from "./_type";

export default function Switch({
  label,
  defaultValue,
  ...props
}: Readonly<ISwitch>) {
  return (
    <NextUISwitch isSelected={defaultValue} {...props}>
      {label}
    </NextUISwitch>
  );
}
