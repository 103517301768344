import { FieldBase } from "@nvs-dynamic-form/react-core";
import { IInput } from "../input";

export class PasswordInputField extends FieldBase<string> {
  override readonly fieldType? = "passwordInput";
  maxLength?: number;
  minLength?: number;

  constructor(options: Omit<PasswordInputField, "fieldType">) {
    super(options, "");
    this.maxLength = options.maxLength;
    this.minLength = options.minLength;
  }
}

export type IPasswordInput = PasswordInputField & IInput;
