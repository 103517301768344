import { ISidebar, ISidebarMenuItem } from "./_type";
import { Link, Listbox, ListboxItem } from "@nextui-org/react";
import {
  MenuItemIcon,
  SidebarBase,
  SideBarLogoContainer,
  SideBarLogo,
} from "./_style";

import { useTranslation } from "../../hooks";

export * from "./_type";

export default function Sidebar({
  menuItems = [],
  isMobileMenu,
}: Readonly<ISidebar>) {
  const { t } = useTranslation();

  const items = menuItems
    .filter((menuItem) => menuItem.visible || menuItem.visible === undefined)
    .map((menuItem: ISidebarMenuItem) => {
      return (
        <ListboxItem
          as={Link}
          href={menuItem.url}
          classNames={{
            title: "text-slate-900 text-[16px]",
            description: "text-slate-500",
            base: "px-5",
            wrapper: "px-3",
          }}
          key={menuItem.url}
          description={t(menuItem.description)}
          startContent={
            <div className="bg-primary/30 rounded-md w-5 h-5">
              <MenuItemIcon className={`${menuItem.iconName} text-2xl`} />
            </div>
          }
        >
          {t(menuItem.title)}
        </ListboxItem>
      );
    });

  return (
    <SidebarBase
      className={`relative z-30 flex h-screen flex-col border-r bg-white ${isMobileMenu ? "open" : ""}`}
    >
      <div className="flex flex-1 flex-col">
        <SideBarLogoContainer>
          <SideBarLogo src={"/images/ai-thinks-logo.png"} alt={"logo"} />
        </SideBarLogoContainer>
        <Listbox variant="flat" aria-label="Listbox menu with descriptions">
          {items}
        </Listbox>
      </div>
    </SidebarBase>
  );
}
