import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  NormalizedCacheObject,
  createHttpLink,
} from "@apollo/client";

import { AuthService } from "../services";
import { configurations } from "../../config/configurations";
import { setContext } from "@apollo/client/link/context";

const httpLink: ApolloLink = createHttpLink({
  uri: configurations.apiEndpoint,
});

const authLink: ApolloLink = setContext((_, { headers }) => {
  const token = AuthService.getToken();
  return {
    headers: token
      ? {
          ...headers,
          Authorization: `Bearer ${token}`,
        }
      : headers,
  };
});

function createApolloClient(): ApolloClient<NormalizedCacheObject> {
  return new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
  });
}

export const apolloClient: ApolloClient<NormalizedCacheObject> =
  createApolloClient();
