import styled from "styled-components";

export const SideBarLogoContainer = styled.div`
  width: 250px;
  padding: 5px;
  display: grid;
  justify-content: center;
`;

export const SideBarLogo = styled.img`
  width: 400px;
  height: 60px;
  max-width: 400px;
`;

export const MenuItemIcon = styled.i`
  color: black;
`;

export const SidebarBase = styled.div`
  @media screen and (max-width: 640px) {
    display: none;
  }

  &.open {
    display: block !important;
    transition: all 0.3s ease;
  }
`;
