import {
  BreadcrumbItem,
  Breadcrumbs as NextUIBreadcrumbs,
} from "@nextui-org/react";

import { IBreadcrumbs } from "./_type";

export * from "./_type";

export default function Breadcrumbs({ items }: Readonly<IBreadcrumbs>) {
  return (
    <NextUIBreadcrumbs className={"mb-5"}>
      {items.map((item) => (
        <BreadcrumbItem
          className={"text-3xl"}
          href={item.url}
          key={item.label}
          isDisabled={item.disabled}
        >
          {item.iconName && (
            <i className={`${item.iconName} text-2xl pb-1`}></i>
          )}
          {item.label}
        </BreadcrumbItem>
      ))}
    </NextUIBreadcrumbs>
  );
}
