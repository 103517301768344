import * as Yup from "yup";

import { CHANGE_PASSWORD } from "./_gql";
import DynamicForm from "../dynamicForm";
import { ErrorHandling } from "../../utils/errorHandling";
import Modal from "../modal";
import { NotificationService } from "../../utils/services";
import { PasswordInputField } from "../passwordInput";
import { useMutation } from "@apollo/client";
import { useTranslation } from "../../hooks";

export default function ChangePassword({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) {
  const { t } = useTranslation();

  const [changePasswordAsync] = useMutation(CHANGE_PASSWORD);

  const onChangePasswordAsync = async (values: any): Promise<void> => {
    try {
      const response = await changePasswordAsync({
        variables: {
          input: {
            currentPassword: values.currentPassword,
            newPassword: values.newPassword,
          },
        },
      });

      NotificationService.success(t(response.data.changePassword.message));
      onClose();
    } catch (error) {
      const errorMessage = ErrorHandling.getErrorMessage(error);
      NotificationService.danger(t(errorMessage));
    }
  };

  const dynamicForm = {
    onSubmit: async (values: unknown) => {
      await onChangePasswordAsync(values);
    },
    submitButtonLabel: t("changePassword"),
    fields: [
      new PasswordInputField({
        id: "currentPassword",
        label: t("currentPassword"),
        screenSize: 12,
        validate: Yup.string().required(t("currentPasswordRequired")),
      }),
      new PasswordInputField({
        id: "newPassword",
        label: t("newPassword"),
        screenSize: 12,
        validate: Yup.string()
          .min(6, t("newPasswordMinLimit"))
          .required(t("newPasswordRequired")),
      }),
      new PasswordInputField({
        id: "newConfirmPassword",
        label: t("newConfirmPassword"),
        screenSize: 12,
        validate: Yup.string()
          .min(6, t("newPasswordMinLimit"))
          .oneOf([Yup.ref("newPassword"), undefined], t("passwordsMustMatch"))
          .required(t("confirmPasswordRequired")),
      }),
    ],
  };

  return (
    <Modal
      title={t("changePassword")}
      iconName={"ri-lock-password-line"}
      isOpen={isOpen}
      size={"xl"}
      onClose={() => {
        onClose();
      }}
    >
      <DynamicForm {...dynamicForm} />
    </Modal>
  );
}
