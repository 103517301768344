import { Checkbox as NextUICheckbox } from "@nextui-org/checkbox";
import { ICheckbox } from "./_type";

export * from "./_type";

export default function Checkbox({
  label,
  defaultValue,
  ...props
}: Readonly<ICheckbox>) {
  return (
    <NextUICheckbox isSelected={defaultValue} {...props}>
      {label}
    </NextUICheckbox>
  );
}
