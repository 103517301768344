import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  User,
} from "@nextui-org/react";
import { IProfileMenu, IProfileMenuItem } from "./_type";

export * from "./_type";

export default function ProfileMenu({
  user,
  menuItems = [],
  color = "primary",
}: Readonly<IProfileMenu>) {
  const menuItemsComponents = menuItems.map((item: IProfileMenuItem) => (
    <DropdownItem onClick={item?.onClick} key={item.label} color={item?.color}>
      {item.label}
    </DropdownItem>
  ));

  return (
    <Dropdown placement="bottom-end">
      <DropdownTrigger>
        <User
          as="button"
          avatarProps={{
            isBordered: true,
            name: `${user?.firstName.charAt(0).toUpperCase()}${user?.lastName.charAt(0).toUpperCase()}`,
            color,
          }}
          className="transition-transform"
          description={`${user?.emailAddress}`}
          name={`${user?.firstName} ${user?.lastName}`}
        />
      </DropdownTrigger>
      <DropdownMenu aria-label="Profile Actions" variant="flat">
        {menuItemsComponents}
      </DropdownMenu>
    </Dropdown>
  );
}
