import {
  ModalBody,
  ModalContent,
  ModalHeader,
  Modal as NextUIModal,
} from "@nextui-org/react";

import { IModal } from "./_type";
import { useTranslation } from "../../hooks";

export default function Modal({
  size = "4xl",
  title,
  iconName,
  children,
  isOpen,
  onClose,
}: Readonly<IModal>) {
  const { t } = useTranslation();
  return (
    <NextUIModal
      size={size}
      isOpen={isOpen}
      onClose={onClose}
      classNames={{
        body: "mt-5 mb-5",
        header:
          "bg-primary text-white align-center flex align-self-center align-items-center",
        closeButton:
          "bg-primary text-white hover:bg-white/15 active:bg-white/30 text-xl mr-1 mt-3",
      }}
    >
      <ModalContent>
        <ModalHeader className="flex flex-row gap-1 text-2xl">
          {iconName && <i className={`${iconName} text-2xl mr-3`}></i>}
          {t(title)}
        </ModalHeader>
        <ModalBody>{children}</ModalBody>
      </ModalContent>
    </NextUIModal>
  );
}
