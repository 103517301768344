import { ITextarea } from "./_type";
import { Textarea as NextUITextarea } from "@nextui-org/react";

export * from "./_type";

export default function Textarea({
  variant = "bordered",
  minRowSize = 1,
  maxRowSize = 5,
  error,
  ...props
}: ITextarea) {
  return (
    <NextUITextarea
      variant={variant}
      minRows={minRowSize}
      maxRows={maxRowSize}
      isInvalid={!!error}
      errorMessage={error}
      {...props}
    />
  );
}
