import { Bounce, toast } from "react-toastify";
import { Id, ToastOptions } from "react-toastify/dist/types";

import { INotificationOptions } from "../../models";

export class NotificationService {
  private static getNotificationOptions(
    option?: INotificationOptions
  ): ToastOptions {
    return {
      position: option?.position ?? "bottom-right",
      autoClose: option?.autoClose ?? 5000,
      hideProgressBar: option?.hideProgressBar ?? false,
      draggable: option?.draggable ?? true,
      theme: option?.theme ?? "colored",
      transition: Bounce,
      icon: option?.iconName ? (
        <i className={`${option?.iconName} text-2xl`}></i>
      ) : (
        false
      ),
    };
  }

  static danger(message: string, option?: INotificationOptions): Id {
    option = {
      ...option,
      iconName: "ri-error-warning-line",
    };
    return toast.error(message, this.getNotificationOptions(option));
  }

  static success(message: string, option?: INotificationOptions): Id {
    option = {
      ...option,
      iconName: "ri-checkbox-circle-line",
    };
    return toast.success(message, this.getNotificationOptions(option));
  }

  static warning(message: string, option?: INotificationOptions): Id {
    option = {
      ...option,
      iconName: "ri-alert-line",
    };
    return toast.warning(message, this.getNotificationOptions(option));
  }

  static info(message: string, option?: INotificationOptions): Id {
    option = {
      ...option,
      iconName: "ri-information-line",
    };
    return toast.info(message, this.getNotificationOptions(option));
  }
}
