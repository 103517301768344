import { IPasswordInput } from "./_type";
import Input from "../input";
import { useState } from "react";

export * from "./_type";

export default function PasswordInput({ ...props }: IPasswordInput) {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const onTogglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  return (
    <Input
      {...props}
      iconName={isPasswordVisible ? "ri-eye-off-line" : "ri-eye-line"}
      onIconClick={onTogglePasswordVisibility}
      type={isPasswordVisible ? "text" : "password"}
    />
  );
}
