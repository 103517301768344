import { InputProps } from "@nextui-org/react";
import { FieldBase } from "@nvs-dynamic-form/react-core";

export class InputField extends FieldBase<string> {
  override readonly fieldType? = "input";
  type?: "email" | "number" | "password" | "tel" | "text" | "url";
  placeholder?: string;
  iconName?: string;
  onIconClick?: () => void;
  maxLength?: number;
  minLength?: number;

  constructor(options: Omit<InputField, "fieldType">) {
    super(options, "");
    this.type = options.type ?? "text";
    this.placeholder = options.placeholder ?? "";
    this.iconName = options.iconName;
    this.onIconClick = options.onIconClick;
    this.maxLength = options.maxLength;
    this.minLength = options.minLength;
  }
}

export type IInput = InputField & InputProps;
