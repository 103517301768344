import { CheckboxGroup as NextUICheckboxGroup } from "@nextui-org/checkbox";
import { ICheckboxGroup, ICheckboxItem } from "./_type";
import Checkbox from "../checkbox";

export * from "./_type";

export default function CheckboxGroup({
  options,
  ...props
}: Readonly<ICheckboxGroup>) {
  return (
    <NextUICheckboxGroup {...props}>
      {options?.map((item: ICheckboxItem) => (
        <Checkbox
          value={item.value.toString()}
          label={item.label}
          id={item.value.toString()}
          key={item.value.toString()}
        />
      ))}
    </NextUICheckboxGroup>
  );
}
